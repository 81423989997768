import { observer } from 'mobx-react';
import { Fragment, useMemo } from 'react';
import { ExperienceContextProvider } from '../../../Common/Experience';
import { CarouselStore } from '../../../Common/Experience/Carousel';
import { ExperienceEnrollmentContextProvider } from '../../../Common/ProgramExperienceEnrollment';
import { ExperienceSection, ExperienceSectionBody } from '../../Common/ExperienceSection';
import EnrollmentStore from '../../Enrollment/Store';
import { useExperiencesContext } from '../../ExperienceSelection/Experiences/ExperiencesContext';
import { PromotableExperienceCarousel } from '../PromotableExperienceCarousel';
import { PromotableExperienceCarouselTile } from '../PromotableExperienceCarouselTile';
import { PromotableSection } from '../PromotableSection';
import { PromotableExperienceResources } from '../Types';
interface Props {
  invitationUuid: string;
  experienceResources: PromotableExperienceResources;
  enrollmentStore: EnrollmentStore;
}
function PromotableExperiencesView({
  invitationUuid,
  experienceResources,
  enrollmentStore,
}: Props) {
  const experiencesStore = useExperiencesContext();
  const carouselStore = useMemo(
    () => new CarouselStore(experienceResources, 1),
    [experienceResources],
  );
  return (
    <PromotableSection>
      <ExperienceSection data-testid="learning-collection-promotable-section" className="p-0">
        <ExperienceSectionBody
          data-testid="learning-collection-promotable-section-body"
          className="pb-10 pt-6 lg:py-0"
        >
          <PromotableExperienceCarousel carouselStore={carouselStore}>
            {({ items }) =>
              items.map((experienceResource) => (
                <Fragment key={experienceResource.payload.id}>
                  <ExperienceEnrollmentContextProvider
                    value={{
                      invitationUuid,
                      experienceResource,
                      enrollmentStore,
                      experiencesStore,
                    }}
                  >
                    <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
                      <PromotableExperienceCarouselTile experience={experienceResource.payload} />
                    </ExperienceContextProvider>
                  </ExperienceEnrollmentContextProvider>
                </Fragment>
              ))
            }
          </PromotableExperienceCarousel>
        </ExperienceSectionBody>
      </ExperienceSection>
    </PromotableSection>
  );
}
export default observer(PromotableExperiencesView);
