import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useFetch, useFindLinkByR } from '../../../../Fetch';
import { useExperienceContext } from '../Experience/ExperienceContext';
import { overviewVideoResourceDecoder } from './Decoders';
import ExperienceVideoDetails from './ExperienceVideoDetails';
import { getVideoDetails } from './Types';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  className?: string;
  experiencesStore: ExperiencesStore;
}

function ExperienceVideoPreview({
  className,
  children = () => <></>,
  experiencesStore,
}: PropsWithChildren<Props>) {
  const { experienceResource } = useExperienceContext();
  const link = useFindLinkByR({ rel: 'overview-video', method: 'get' }, experienceResource.links);
  const state = useFetch(link, overviewVideoResourceDecoder);

  switch (state.kind) {
    case 'ready':
      return getVideoDetails(state.data)
        .map(({ video, sources, thumbnail }) => (
          <ExperienceVideoDetails
            className={className}
            title={experienceResource.payload.title}
            video={video}
            sources={sources.toArray()}
            thumbnail={thumbnail.href}
            experiencesStore={experiencesStore}
          />
        ))
        .getOrElse(() => <>{children}</>);
    case 'loading':
    case 'errored':
      return <>{children}</>;
  }
}

export default observer(ExperienceVideoPreview);
