import { isEmpty } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  editPasswordRoute,
  freebusyRoute,
  resetPasswordRoute,
  ssoAuthErrorRoute,
  ssoLoginErrorRoute,
  unsubscribeRoute,
} from '../../ClientRoutes';
import Logout from '../../Logout';
import DiscoveryPortal from '../../Native/AEP/DiscoveryPortal';
import {
  PlatformMode,
  PreviewModeProvider,
} from '../../Native/AEP/DiscoveryPortal/PreviewMode/PreviewModeContext';
import SharedOpenEnrollment from '../../Native/AEP/SharedOpenEnrollment';
import { MatchIDProps, MatchUUIDProps, embed, useCaseId, useCaseUuid } from '../../Preview/Types';
import { sessionStore } from '../../Session/Store';
import ThemeContext, { currentGlobalTheme } from '../../Themes/ThemeContext';
import { AutoLaunchableContext } from '../AutoLaunchable/AutoLaunchableContext';
import EditPassword from '../EditPassword';
import FreebusyTest from '../FreeBusyTest';
import ImpersonationMessage from '../ImpersonationMessage';
import ResetPassword from '../ResetPassword';
import SsoError from '../SsoError';
import Unsubscribe from '../Unsubscribe';
import AppView from './AppView';
import { appsignalDiscoveryPortal } from '../../appsignal';
import { withErrorBoundary } from '.';

const AppRoutes: React.FC = () => (
  <AutoLaunchableContext.Provider value="discovery_portal_and_learning_platform">
    <Switch>
      <Route path={resetPasswordRoute} exact={true} component={ResetPassword} />
      <Route path={editPasswordRoute} exact={true} component={EditPassword} />
      <Route
        path={ssoLoginErrorRoute}
        component={() => (
          <ThemeContext.Provider value={currentGlobalTheme()}>
            <SsoError
              errorMessage={
                'You successfully authenticated using a third party identity provider, but ...'
              }
            />
          </ThemeContext.Provider>
        )}
      />
      <Route
        path={ssoAuthErrorRoute}
        component={() => (
          <ThemeContext.Provider value={currentGlobalTheme()}>
            <SsoError
              errorMessage={
                'We have not heard back from your corporate system. Please try again later or contact customer support.'
              }
            />
          </ThemeContext.Provider>
        )}
      />
      <Route path={freebusyRoute} exact={true} component={FreebusyTest} />
      <Route
        exact
        path={unsubscribeRoute}
        render={(props) => <Unsubscribe uuid={props.match.params.uuid} />}
      />
      <Route
        path="/preview/:id"
        render={({ match }: MatchIDProps) =>
          withErrorBoundary(
            <PreviewModeProvider
              value={when<PlatformMode>(embed, 'preview').getOrElseValue('default')}
            >
              <DiscoveryPortal identifier={useCaseId(match.params.id)} params={nothing()} />
            </PreviewModeProvider>,
            appsignalDiscoveryPortal,
          )
        }
      />
      <Route
        path="/register/:uuid"
        render={({ match }: MatchUUIDProps) => {
          const queryParams = when(
            (search) => !isEmpty(search),
            decodeURI(window.location.search),
          ).map((uri) => new URLSearchParams(uri));
          return withErrorBoundary(
            <>
              <ImpersonationMessage impersonatingBy={sessionStore.impersonatingBy} />

              <PreviewModeProvider value={'default'}>
                <DiscoveryPortal identifier={useCaseUuid(match.params.uuid)} params={queryParams} />
              </PreviewModeProvider>
            </>,
            appsignalDiscoveryPortal,
          );
        }}
      />
      <Route
        path="/shared/:opaqueUseCaseIdentifier"
        exact
        render={(props) =>
          withErrorBoundary(
            <>
              <ImpersonationMessage impersonatingBy={sessionStore.impersonatingBy} />
              <SharedOpenEnrollment
                opaqueUseCaseIdentifier={props.match.params.opaqueUseCaseIdentifier}
              />
            </>,
            appsignalDiscoveryPortal,
          )
        }
      />
      <Route path="/logout" exact={true} component={Logout} />
      <Route path="*" component={AppView} />
    </Switch>
  </AutoLaunchableContext.Provider>
);

export default observer(AppRoutes);
