import { Route, Router, Switch } from 'react-router-dom';
import history from '../../History';
import { useMessageBus } from '../../MessageBus';
import RootLoaded from '../../RootLoaded';
import { Routes as ServiceRoutes } from '../../Services/Routes';
import WhenApplicationSession from '../../Session/WhenApplicationSession';
import { TranslationsLoader, loader } from '../../Translations';
import Loader from '../Loader';
import Tooling from '../Tooling';
import AppReactions from './AppReactions';
import AppRoutes from './AppRoutes';
import { ErrorBoundary } from '@appsignal/react';
import { JSClient } from '@appsignal/types';
import { Maybe } from 'maybeasy';
import { appsignalP3 } from '../../appsignal';

export const withErrorBoundary = (element: JSX.Element, instance: Maybe<JSClient>) => {
  return instance
    .map((instance: JSClient) => (
      <ErrorBoundary action="App" instance={instance}>
        {element}
      </ErrorBoundary>
    ))
    .getOrElse(() => element);
};

export function App() {
  return withErrorBoundary(
    <Router history={history}>
      <Switch>
        <Route path="/service/*" component={ServiceRoutes} />
        <Route path="*" component={_App} />
      </Switch>
    </Router>,
    appsignalP3,
  );
}

function _App() {
  useMessageBus();

  return (
    <TranslationsLoader loader={loader('en')} loading={<Loader delay={0} />}>
      <RootLoaded>
        <Tooling />
        <AppReactions />
        <WhenApplicationSession>
          <AppRoutes />
        </WhenApplicationSession>
      </RootLoaded>
    </TranslationsLoader>
  );
}
